<template>
    <!-- Pantalla inicio: consumidor financiero -->
    <div class="w-10/12 m-auto flex flex-col items-center">
        <h2 class="w-full text-2xl sm:text-2xl md:text-4xl text-left text-blue-dark"><b>¡Bienvenido, {{ name }}!</b></h2>
        <p class="w-full text-md md:text-lg lg:text-xl mt-2 mb-5 text-left text-gray-500">¿Qué desea hacer?</p>
        <!-- Opciones -->
        <div class="w-full max-w-8xl flex flex-col justify-center items-center lg:flex-row gap-8">
            <!-- Opción: Queja -->
            <router-link to="./complaint/registration"
                class="m-5 max-w-md sm:w-4/5 md:w-3/5 mx-auto my-5 flex flex-col self-stretch bg-white rounded-2xl shadow-md cursor-pointer"
                append>
                <div class="p-5 border-b-4 border-blue-dark h-3/6 w-full">
                    <div class="h-14 w-14 m-auto flex justify-center items-center rounded-full bg-white border-2">
                        <img src="@/assets/icons/file.svg" alt="">
                    </div>
                    <h3 class="mt-2 text-blue-dark text-center text-xl md:text-2xl justify-between"><b>Presentar una queja, conciliación o demanda</b></h3>
                </div>
                <div class="h-3/6 w-full p-4 mb-3 flex flex-col justify-between">
                    <p class="p-5 text-sm text-gray-600">
                        Suministre la información relacionada con su inconformidad frente a un producto o servicio del
                        sistema financiero.
                    </p>
                    <a-button type="primary" shape="round" :block="true">
                        DILIGENCIAR FORMULARIO
                    </a-button>
                </div>
            </router-link>
            <!-- Opción: Seguir queja -->
            <router-link to="./complaint/tracking"
                class="m-5 max-w-md sm:w-4/5 md:w-3/5 mx-auto my-5 flex flex-col self-stretch bg-white rounded-2xl shadow-md cursor-pointer"
                append>
                <div class="p-5 border-b-4 border-blue-dark h-3/6 w-full">
                    <div class="h-14 w-14 m-auto flex rounded-full bg-white border-2">
                        <img src="@/assets/icons/eye.svg" alt="">
                    </div>
                    <h3 class="h-4/6 mt-2 text-blue-dark text-xl md:text-2xl justify-center flex items-center"><b>Seguimiento de quejas</b></h3>
                </div>
                <div class="h-3/6 w-full p-4 mb-3 flex flex-col justify-between">
                    <p class="p-5 text-sm text-gray-600 justify-between">
                        Estado actual de una o más inconformidades presentadas con anterioridad.
                    </p>
                    <a-button type="primary" shape="round" :block="true">
                        HACER SEGUIMIENTO
                    </a-button>
                </div>
            </router-link>
            <!-- Opción: Seguir Demanda -->
            <a href="https://www.superfinanciera.gov.co/jsp/10105184" target="_blank" rel="noopener"
                class="m-5 max-w-md sm:w-4/5 md:w-3/5 mx-auto my-5 flex flex-col self-stretch bg-white rounded-2xl shadow-md cursor-pointer"
                append>
                <div class="p-5 border-b-4 border-blue-dark h-3/6 w-full">
                    <div class="h-14 w-14 m-auto flex rounded-full bg-white border-2">
                        <img src="@/assets/icons/eye.svg" alt="">
                    </div>
                    <h3 class="h-4/6 mt-2 text-blue-dark text-xl md:text-2xl justify-center flex items-center"><b>Seguimiento de demandas</b></h3>
                </div>
                <div class="h-3/6 w-full p-4 mb-3 flex flex-col justify-between">
                    <p class="p-5 text-sm text-gray-600">
                        Estado actual de una o más demandas presentadas con anterioridad.
                    </p>
                    <a-button type="primary" shape="round" :block="true">
                        HACER SEGUIMIENTO
                    </a-button>
                </div>
            </a>
        </div>
    </div>
</template>
<script>
export default {
    components: {
    },
    computed: {
        name() {
            if (this.$store.state.session.user) {
                let name = this.$store.state.session?.user?.person?.person_type === 2 ? this.$store.state.session.user.business_name : this.$store.state.session.user.first_name;
                return name;
            }
            return '';

        }
    }
}
</script>